// material ui
import Stack from '@mui/material/Stack';

// project import
import NavList from 'src/layouts/main/nav/desktop/nav-list';

// context
import useAuth from 'src/hooks/use-auth';

// types
import { NavProps } from 'src/layouts/main/nav/types';

// ----------------------------------------------------------------------

export default function NavDesktop({ data, sx }: NavProps) {
    const { userInfo } = useAuth();

    return (
        <Stack
            component="nav"
            direction="row"
            spacing={5}
            sx={{
                height: 1,
                ...sx,
            }}
        >
            {data.map((link) => {
                return (
                    (link.permission == null || link.permission.includes(userInfo?.status)) && (
                        <NavList key={link.title} item={link} />
                    )
                );
            })}
        </Stack>
    );
}
